.sliders{
    height: calc(100vh - 80px);
  /* width: 100vw; */
    position: relative;
    overflow: hidden;
    margin-top: -141px;
  }


 .containerss{
      width: 300vw;
      height: 100%;
      display: flex;
      transition: all 1s ease;
    }

  img{
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }


  .iconsss{
      width: fit-content;
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100px;
      gap: 10px;
      margin: auto;
      color: #fff;
  
    }

 .iconss{
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }