/*** Header ***/
.page-headers::before,
.page-headers::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 10px;
    top: 0;
    left: 0;
    background: url(../../public/static/img/bg-header-top.png) center center repeat-x;
    z-index: 1;
}

/* .page-headers::after {
    height: 19px;
    top: auto;
    bottom: 0;
    background: url(../../public/static/img/bg-header-top.png) center center repeat-x;
} */

.page-headers {
    background: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(../../public/static/img/bg-header-top.png) center center no-repeat;
    background-size: cover;
}