/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  } */
  
  .containers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 40px 20px 0 20px;
  }
  
  .containers .heading{
    width: 50%;
    padding-bottom: 50px;
  }
  .containers .heading h3{
    font-size: 3em;
    font-weight: bolder;
    padding-bottom: 10px;
    border-bottom: 3px solid #222;
  }
  
  .containers .heading h3 span{
    font-weight: 100;
  }
  .containers .box{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
  }
  
  .containers .box .dream{
    display: flex;
    flex-direction: column;
    width: 32.5%;
  }
  
  .containers .box .dream img{
    width: 100%;
    padding-bottom: 15px;
    border-radius: 5px;
  }

  .containers .box .dream video{
    width: 100%;
    padding-bottom: 15px;
    border-radius: 5px;
  }
  
  
  .containers .btn{
    margin: 40px 0 70px 0;
    background: #222;
    padding: 15px 40px ;
    border-radius: 5px;
  }
  
  .containers .btn a{
   color: #fff;
   font-size: 1.2em;
   text-decoration: none;
   font-weight: bolder;
   letter-spacing: 3px;
  }
  
  @media only screen and (max-width: 769px){
      .containers .box{
     flex-direction: column;
    }
  
  .containers .box .dream{
    width: 100%;
  }  
  }
  
  @media only screen and (max-width: 643px){
  .containers .heading{
    width: 100%;
  }
  .containers .heading h3{
    font-size: 1em;
  
  }
  
  }